/* eslint-disable react/no-children-prop */
/* eslint-disable import/no-cycle */
import React from 'react';
import { Text } from 'components/styleguide';
import { Trans } from 'react-i18next';
import { rootTranslation } from 'utils/format';
import FileSaver from 'file-saver';

/**
 * @param {Object} importResult
 * @param {Number} importResult.recordsOk
 * @param {Number} importResult.recordsWithErrors
 * @param {String} importResult.url
 * @returns {Object} The prompt props
 */
export const getImportFinishedPrompt = ({ recordsWithErrors, url }) => {
  const t = rootTranslation('drawers.importSaveAsTemplate.notifications');
  const content = (
    <div style={{ position: 'absolute', top: 55 }}>
      <Text children={t('added')} color="textPrimary" />
      {Boolean(recordsWithErrors) && <Text children={t('failed')} color="textPrimary" />}
      {Boolean(recordsWithErrors && url) && (
        <Trans i18nKey="drawers.importSaveAsTemplate.notifications.download">
          <Text color="textPrimary" link onClick={() => FileSaver.saveAs(url)} variant="overline" />
          <Text color="textPrimary" variant="overline" />
        </Trans>
      )}
    </div>
  );
  return {
    content,
    multiOk: false,
    okLabel: t('ok'),
    onlyOk: true,
    title: t('importComplete'),
  };
};
